import React from 'react';
import {
  Image,
  Heading,
  Text,
  Stack,
  Show,
  Flex,
  Highlight,
} from '@chakra-ui/react';

import phone from './Mockup_front.png';
import { ShowDesktop, ShowMobile } from '../../utils/Show';
import { Paragraph } from '../../utils/Paragraph';

const DROP_SHADOW = 'drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.3))';
// 'drop-shadow(0px 1.7px 1.4px rgba(0, 0, 0, 0.034)) drop-shadow(0px 4.5px 3.3px rgba(0, 0, 0, 0.048)) drop-shadow(0px 9px 6.3px rgba(0, 0, 0, 0.06)) drop-shadow(0px 16.4px 11.2px rgba(0, 0, 0, 0.072)) drop-shadow(0px 28.9px 20.9px rgba(0, 0, 0, 0.086)) drop-shadow(0px 50px 50px rgba(0, 0, 0, 0.12))';

const Title = () => (
  <Flex direction={'column'} textAlign={{ base: 'center', md: 'left' }}>
    <Heading fontSize={{ base: '30px', md: '48px' }}>Discover. Match.</Heading>
    <Heading fontSize={{ base: '30px', md: '48px' }}>
      Experience together.
    </Heading>
  </Flex>
);

const Slogan = () => (
  <Text
    paddingTop={5}
    fontSize={'18px'}
    lineHeight={1.7}
    textAlign={{ base: 'center', md: 'left' }}
  >
    <Paragraph>
      Diskutiert ihr noch oder swiped ihr schon? <br />
    </Paragraph>
    <Paragraph>
      Gemeinsamer Filmabend oder Serienmarathon - Unsere App unterstützt dich
      und deine Freunde bei der Entscheidungsfindung und macht sie spielend
      leicht.
      <br />
    </Paragraph>

    <Paragraph fontWeight={'600'}>
      Matchingly. Die App, die eure gemeinsamen Entscheidungen auf's nächste
      Level bringt!
      <br />
    </Paragraph>
    <Paragraph>Stay tuned.</Paragraph>
  </Text>
);

export const Home = () => (
  <>
    <Stack
      spacing={{ base: 8, md: 0 }}
      direction={{ base: 'column', md: 'row' }}
    >
      <Flex
        flex={1}
        direction='column'
        paddingTop={{ base: 10, md: 20 }}
        paddingBottom={{ base: 0, md: 20 }}
      >
        <Title />
        <ShowDesktop>
          <Slogan />
        </ShowDesktop>
      </Flex>
      <Flex flex={1} justifyContent={'center'}>
        <Image
          src={phone}
          style={{ filter: DROP_SHADOW }}
          maxH={{ base: '360px', md: '600px' }}
          marginTop={{ base: 10, md: 0 }}
          marginBottom={{ base: 10, md: 0 }}
        />
      </Flex>
    </Stack>
    <ShowMobile>
      <Slogan />
    </ShowMobile>
  </>
);
