import React from 'react';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Background } from './Background';
import { theme } from './theme';

import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';

import ScrollToTop from 'react-router-scroll-top';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <ScrollToTop>
        <Background />
        <Flex minH='100vh' justifyContent='center'>
          <Flex direction='column' w='7xl' marginX={10}>
            <Header />
            <Routes />
            <Footer />
          </Flex>
        </Flex>
      </ScrollToTop>
    </Router>
  </ChakraProvider>
);
