import React from 'react';
import { Image, Box, Flex } from '@chakra-ui/react';
import logo from './logo.svg';
import logoNoText from './logoNoText.svg';

import { Link, Route, Switch } from 'react-router-dom';

export const Header = () => (
  <Flex paddingTop={8} justifyContent={{ base: 'center', md: 'left' }}>
    <Switch>
      <Route exact path='/'>
        <Image src={logo} />
      </Route>
      <Route path='*'>
        <Link to={'/'}>
          <Image src={logoNoText} w={8} h={8} />
        </Link>
      </Route>
    </Switch>
  </Flex>
);
