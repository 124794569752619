import React from 'react';
import { Flex, Icon, Link } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

export const Footer = () => (
  <Flex paddingTop={10} paddingBottom={10} justifyContent={'space-between'}>
    <RouterLink to={'/imprint'}>Impressum</RouterLink>
    <Link href='https://instagram.com/matchingly.io' target={'_blank'}>
      <Icon as={FaInstagram} w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />
    </Link>
  </Flex>
);
