import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const fonts = {
  heading: `'Roboto', sans-serif`,
  body: `'Roboto', sans-serif`,
};

const styles = {
  global: {
    body: {
      bg: '#181818',
    },
  },
};

export const theme = extendTheme({ config, fonts, styles });
