import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { Home } from './Home';
import { Imprint } from './Imprint';

export const Routes = () => (
  <Box flex={1}>
    <Switch>
      <Route path='/imprint'>
        <Imprint />
      </Route>
      <Route path='/'>
        <Home />
      </Route>
    </Switch>
  </Box>
);
